@import '../../../styles/var';

.offersWrap {
    position: relative;
}

.paint {
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-45%);
    top: -120px;
    width: 959px;
    @media(max-width: $md3+px) {
        width: 800px;
        top: -80px;
    }
    @media(max-width: $md3+px) {
        width: 600px;
        top: -20px;
    }
    @media(max-width: 800px) {
        width: 500px;
    }
}

.title {
    position: absolute;
    width: 100%;
    z-index: 11;
    top: 172px;
    left: 0;
    color: #FCF9F4;
    text-align: center;
    font-family: $furore;
    font-size: 70px;
    font-weight: 400;
    line-height: normal;
    @media(max-width: $md3+px) {
        top: 90px;
    }
    @media(max-width: $md4+px) {
        font-size: 45px;
        top: 50px;
    }
    @media(max-width: 800px) {
        position: relative;
        top: unset;
        left: unset;
        margin-bottom: 80px;
    }
    @media(max-width: $md6+px) {
        margin-bottom: 60px;
        font-size: 30px;
    }
}

.offers {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(2, 354px);
    justify-content: space-between;
    align-items: center;
    gap: 110px 130px;
    @media(max-width: $md3+px) {
        grid-template-columns: repeat(2, 300px);
    }
    @media(max-width: $md4+px) {
        gap: 90px 80px;
        grid-template-columns: repeat(2, 300px);
    }
    @media(max-width: 800px) {
        grid-template-columns: repeat(2, 1fr);
        gap: 80px;
    }
    @media(max-width: 700px) {
        grid-template-columns: repeat(1, 1fr);
        gap: 60px;
    }
}

.card {
    position: relative;
    width: 100%;
    @media(max-width: 800px) {
        transform: none!important;
    }
    @media(max-width: 700px) {
        width: 300px;
        margin: 0 auto;
    }
    @media(max-width: $md6+px) {
        width: 260px;
    }
    &_03 {
        transform: translateX(140px);
        @media(max-width: $md3+px) {
            transform: translateX(110px);
        }
        @media(max-width: $md4+px) {
            transform: translateX(50px);
        }

    }
    &_04 {
        transform: translateX(-140px);
        @media(max-width: $md3+px) {
            transform: translateX(-110px);
        }
        @media(max-width: $md4+px) {
            transform: translateX(-50px);
        }
    }
    &Person {
        position: relative;
        z-index: 5;
        height: 327px;
        display: flex;
        margin: 0 auto;
        @media(max-width: $md3+px) {
            height: 230px;
        }
        @media(max-width: $md4+px) {
            height: 180px;
        }
        @media(max-width: $md6+px) {
            height: 160px;
        }
    }
    &Link {
        position: relative;
        z-index: 10;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: rgba(255, 255, 255, 0.88);
        font-family: $furore;
        font-size: 17px;
        font-weight: 400;
        line-height: normal;
        width: 100%;
        height: 56px;
        text-transform: uppercase;
        
        border-radius: 10px;
        border: 0.5px solid #918F90;
        background: linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
        box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
        text-shadow: 0px 0px 2px rgba(241, 237, 238, 0.40);

        @media(max-width: $md4+px) {
            font-size: 16px;
        }
        @media(max-width: $md6+px) {
            font-size: 14px;
            height: 48px;
        }
        &:hover {
            background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
            box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.5px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
        }
    }
}
