@import '../../../styles/var';

.howItWorkWrap {
    position: relative;
    .bg {
        position: absolute;
        top: -250px;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%);
        width: 1520px;
        @media(max-width: $md4+px) {
            width: 1100px;
            top: -130px;
        }
        @media(max-width: 650px) {
            width: 1520px;
            top: -260px;
            opacity: 0.7;
        }
    }
    .hand {
        position: absolute;
        z-index: 3;
        right: 0;
        top: -200px;
        width: 718px;
        @media(max-width: $md2+px) {
            width: 650px;
            top: -160px;
        }
        @media(max-width: $md3+px) {
            width: 450px;
            top: -50px;
        }   
        @media(max-width: $md4+px) {
            width: 300px;
            top: -110px;
        }
        @media(max-width: $md5+px) {
            width: 220px;
            top: -80px;
        }
        @media(max-width: 650px) {
            transform: rotate(-30deg);
            right: -62px;
            top: 60px;
        }
    }
}

.howItWork {
    position: relative;
    .content {
        position: relative;
        z-index: 10;
        .title {
            color: #FCF9F4;
            font-family: $furore;
            font-size: 70px;
            font-weight: 400;
            line-height: normal;
            @media(max-width: $md4+px) {
                font-size: 45px;
            }
            @media(max-width: 650px) {
                text-align: center;
            }
            @media(max-width: $md6+px) {
                font-size: 30px;
            }
        }
        .cards {
            display: grid;
            grid-template-columns: repeat(2, 260px);
            gap: 60px 110px;
            margin-top: 80px;
            @media(max-width: $md4+px) {
                margin-top: 65px;
                grid-template-columns: repeat(2, 1fr);
                gap: 60px 90px;
            }
            @media(max-width: 650px) {
                display: flex;
                flex-direction: column;
                gap: 30px;
            }
            @media(max-width: $md6+px) {
                margin-top: 50px;
            }
            .card {
                &Num {
                    color: #FFF;
                    font-family: $furore;
                    font-size: 28px;
                    font-weight: 400;
                    line-height: 140%;
                    @media(max-width: $md4+px) {
                        font-size: 23px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 20px;
                    }
                }
                &Line {
                    margin: 2px 0 10px 0;
                    width: 100%;
                    height: 1px;
                    background: linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
                    @media(max-width: $md4+px) {
                        margin-top: 6px;
                    }
                    @media(max-width: $md6+px) {
                        margin: 8px 0 15px 0;
                    }
                }
                &Text {
                    font-weight: 400;
                    @media(max-width: 700px) {
                        br {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}