@import '../../../styles/var';
@import '../../../styles/mixins';

.minDep {
    position: relative;
    z-index: 10;
    .cards {
        display: flex;
        flex-direction: column;
        gap: 65px;
        @media(max-width: $md1+px) {
            gap: 50px;
        }
        .card {
            position: relative;
            width: 621px;
            padding-top: 202px;
            @media(max-width: $md1+px) {
                padding-top: 160px;
            }
            @media(max-width: $md3+px) {
                margin: 0 auto!important;
                width: 100%;
                max-width: 600px;
            }
            @media(max-width: $md4+px) {
                padding-top: 120px;
            }
            @media(max-width: $md6+px) {
                padding-top: 70px;
            }
            &:nth-child(1) {
                @media(max-width: $md6+px) {
                    .image {
                        top: -15px;
                    }
                }
            }
            &:nth-child(2) {
                margin-left: auto;
            }
            .title {
                position: relative;
                z-index: 11;
                color: #FFF;
                font-family: $furore;
                font-size: 25px;
                font-weight: 400;
                line-height: 150%;
                @media(max-width: $md4+px) {
                    font-size: 20px;
                }
                @media(max-width: $md6+px) {
                    font-size: 16px;
                }
            }
            .line {
                position: relative;
                width: 100%;
                height: 3px;
                background: rgba($color: white, $alpha: 0.5);
                margin: 25px 0 30px 0;
                @media(max-width: $md6+px) {
                    margin: 15px 0 20px 0;
                }
            }
            .btnWrap {
                .button {
                    cursor: pointer;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    padding: 24px 34px;
                    border-radius: 74px;
                    border: 0.5px solid #918F90;
                    background: linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                    box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
                    color: #F1EDEE;
                    text-shadow: 0px 0px 2px rgba(241, 237, 238, 0.40);
                    font-family: $furore;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: normal;
                    @media(max-width: $md4+px) {
                        font-size: 16px;
                        padding: 21px 34px;
                    }
                    @media(max-width: $md6+px) {
                        padding: 17px 24px;
                        font-size: 14px;
                    }
                    &:hover {
                        background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                        box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.5px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
                    }
                }
            }
            .image {
                position: absolute;
                right: -5px;
                top: 0;
                width: 290px;
                @media(max-width: $md1+px) {
                    width: 250px;
                }
                @media(max-width: $md4+px) {
                    width: 200px;
                    right: 10px;
                }
                @media(max-width: $md6+px) {
                    width: 140px;
                    right: -10px;
                }
            }
        }
    }
}