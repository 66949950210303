@import '../../../styles/var';
@import '../../../styles/mixins';

.bannerWrap {
    position: relative;
    overflow: hidden;
    max-width: 2000px;
    margin: 0 auto;
    .bgWrap {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: 100%;
        min-width: 1920px;
        @media(max-width: $md1+px) {
            min-width: 1640px;
        }
        @media(max-width: $md3+px) {
            min-width: 2000px;
        }
        @media(max-width: $md6+px) {
            min-width: 1350px;
        }
        .bg {
            position: absolute;
            width: 100%;
            // top: 100px;
            bottom: -50px;
            left: 0;
            @media(max-width: $md3+px) {
                top: unset;
                bottom: 0;
            }
        }
        .dark {
            position: absolute;
            z-index: 3;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 80px;
            background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 0%, #000 100%);
            @media(max-width: $md3+px) {
                display: none;
            }
        }
        @media(min-width: 2000px) {
            &::before, &::after {
                content: '';
                position: absolute;
                z-index: 3;
                width: 100px;
                height: 100%;
                top: 0;
            }
            &::before {
                left: 0;
                background: linear-gradient(-90deg, #000 0%, rgba(0, 0, 0, 0) 0%, #000 100%);
            }
            &::after {
                right: 0;
                background: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0) 0%, #000 100%);
            }
        }
    }
}

.banner {
    position: relative;
    z-index: 10;
    padding-top: 180px;
    height: 100vh;
    max-height: 950px;
    min-height: 740px;
    @media(max-width: $md1+px) {
        max-height: 850px;
        padding-top: 150px;
    }
    @media(max-width: $md3+px) {
        height: initial;
        min-height: initial;
        max-height: initial;
        padding-top: 200px;
        padding-bottom: 380px;
    }
    @media(max-width: 600px) {
        padding-bottom: 210px;
    }
    @media(max-width: $md6+px) {
        padding-top: 150px;
    }
    .content {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;
        align-items: center;
        .header {
            position: relative;
            display: inline-flex;
            .title {
                display: inline-flex;
                color: #FCF9F4;
                text-shadow: 0px 0px 44.459999084472656px #000, 0px 0px 88.91999816894531px #000, 0px 0px 311.2200012207031px #000, 0px 0px 622.4400024414062px #000, 0px 0px 1067.0400390625px #000, 0px 0px 1867.3199462890625px #000;
                font-family: $righteous;
                font-size: 130px;
                font-weight: 400;
                line-height: normal;
                @media(max-width: $md4+px) {
                    font-size: 120px;
                }
                @media(max-width: $md6+px) {
                    font-size: 80px;
                }
            }
            .subtitle {
                position: absolute;
                left: 0;
                top: 120px;
                color: #FCF9F4;
                font-family: $caveat;
                font-size: 35px;
                font-weight: 400;
                line-height: normal;
                @media(max-width: $md4+px) {
                    top: 118px;
                    font-size: 30px;
                }
                @media(max-width: $md6+px) {
                    top: 78px;
                    font-size: 25px;
                }
            }
        }
        .logoWrap {
            text-align: center;
            margin: 43px 0 40px 0;
            width: 112px;
            @media(max-width: $md3+px) {
                margin: 57px 0 65px 0;
            }
            @media(max-width: $md6+px) {
                margin-top: 54px;
            }
            .logo {
                position: relative;
                width: 100%;
            }
        }
        .buttons {
            display: flex;
            justify-content: center;
            gap: 150px;
            @media(max-width: $md4+px) {
                gap: 90px;
            }
            @media(max-width: 600px) {
                width: 240px;
                flex-direction: column;
                justify-content: initial;
                gap: 30px;
            }
            @media(max-width: $md6+px) {
                width: 200px;
            }
            .button {
                cursor: pointer;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 24px 34px;
                border-radius: 74px;
                border: 0.5px solid #918F90;
                background: linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
                color: #F1EDEE;
                text-shadow: 0px 0px 2px rgba(241, 237, 238, 0.40);
                font-family: $furore;
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
                @media(max-width: $md4+px) {
                    font-size: 16px;
                    padding: 21px 34px;
                }
                @media(max-width: 600px) {
                    display: flex;
                    width: 100%;
                }
                @media(max-width: $md6+px) {
                    padding: 17px 10px;
                    font-size: 14px;
                }
                &:hover {
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                    box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.5px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
                }
            }
        }
    }
}