@import '../../../styles/var';

.talkAbout {
    position: relative;
    z-index: 10;
    .title {
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #FFF;
        width: 195px;
        text-align: center;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: calc(50% - 10px);
        @media(max-width: $md5+px) {
            position: relative;
            width: unset;
            top: unset;
            left: unset;
            right: unset;
            margin: unset;
        }
    }
    .mob_content {
        @media(min-width: 769px) {
            display: none;
        }
        margin-top: 20px;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(4, 0fr);
        justify-content: space-between;
        gap: 20px 0;
    }
    .content {
        position: relative;
        z-index: 10;
        display: flex;
        margin: 0 auto;
        @media(max-width: $md4+px) {
            transform: scale(0.9);
        }
        @media(max-width: $md5+px) {
            display: none;
        }
        .ellipse {
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            display: flex;
            &_big {
                position: relative;
                margin: 0 auto;
                height: 700px;
                width: 700px;
                background-image: url('../../../assets/img/HomePage/TalkAbout/ellipse_big.png');
            }
            &_medium {
                position: absolute;
                top: calc(50% - 250px);
                left: calc(50% - 250px);
                height: 500px;
                width: 500px;
                background-image: url('../../../assets/img/HomePage/TalkAbout/ellipse_medium.png');
            }
            &_small {
                position: absolute;
                top: calc(50% - 150px);
                left: calc(50% - 150px);
                height: 300px;
                width: 300px;
                background-image: url('../../../assets/img/HomePage/TalkAbout/ellipse_small.png');
            }
            .nb_aff_paying {
                
            }
            .nb_aff_plus {
    
            }
            .brand {
                position: absolute;
                z-index: 10;
                a {
                    img {
                        width: 100%;
                    }
                }
                // big
                &_dell {
                    top: -20px;
                    left: 200px;
                }
                &_pirate {
                    top: 10px;
                    right: 140px;
                }
                &_amazon {
                    top: 160px;
                    right: 0;
                }
                &_sony {
                    bottom: 120px;
                    right: 20px;
                }
                &_nestle {
                    bottom: -35px;
                    left: 300px;
                }
                &_coca {
                    bottom: 160px;
                    left: 0;
                }
                &_aff_paying {
                    top: 170px;
                    left: -5px;
                }
                // medium
                &_samsung {
                    top: 110px;
                    left: -10px;
                }
                &_gap {
                    right: 40px;
                    top: 30px;
                }
                &_lenovo {
                    bottom: 60px;
                    right: 20px;
                }
                &_intel {
                    bottom: -10px;
                    left: 100px;
                }
                // small
                &_ebay {
                    top: -30px;
                    left: 130px;
                }
                &_geforse {
                    top: 190px;
                    right: -10px;
                }
                &_aff_plus {
                    left: -30px;
                    top: 140px;
                }
            }
        }
    
        .ellipse_big {
            -webkit-animation: rotating-right 60s linear infinite;
            -moz-animation: rotating-right 60s linear infinite;
            -ms-animation: rotating-right 60s linear infinite;
            -o-animation: rotating-right 60s linear infinite;
            animation: rotating-right 60s linear infinite;
        }
        .ellipse_medium, .brand_small {
            -webkit-animation: rotating-left 35s linear infinite;
            -moz-animation: rotating-left 35s linear infinite;
            -ms-animation: rotating-left 35s linear infinite;
            -o-animation: rotating-left 35s linear infinite;
            animation: rotating-left 35s linear infinite;
            
        }
        .ellipse_small, .brand_medium {
            -webkit-animation: rotating-right 35s linear infinite;
            -moz-animation: rotating-right 35s linear infinite;
            -ms-animation: rotating-right 35s linear infinite;
            -o-animation: rotating-right 35s linear infinite;
            animation: rotating-right 35s linear infinite;
        }
    
        .brand_big {
            -webkit-animation: rotating-left 60s linear infinite;
            -moz-animation: rotating-left 60s linear infinite;
            -ms-animation: rotating-left 60s linear infinite;
            -o-animation: rotating-left 60s linear infinite;
            animation: rotating-left 60s linear infinite;
        }
    }
}


@-webkit-keyframes rotating-right {
from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}
to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
}
}
@keyframes rotating-right {
from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}
to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
}
}
@-webkit-keyframes rotating-left {
from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}
to {
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
}
}
@keyframes rotating-left {
from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
}
to {
    -ms-transform: rotate(-360deg);
    -moz-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
    -o-transform: rotate(-360deg);
    transform: rotate(-360deg);
}
}