@import '../../../styles/var';

.noRestriction {
    position: relative;
    z-index: 10;
    border: 1px solid #FCF9F4;
    padding: 60px 0;
    background: transparent;
    transition: all .3s;
    border-radius: 20px;

    font-size: 70px;
    font-style: normal;
    line-height: normal;
    @media(max-width: $md4+px) {
        font-size: 60px;
        padding: 50px 0;
    }
    @media(max-width: $md6+px) {
        font-size: 50px;
        padding: 40px 0;
    }

    &:hover {
        border: 1px solid transparent;
        &::before {
            opacity: 1;
        }
        .line {
            text-stroke: 1px #000!important;
            -webkit-text-stroke: 1px #000!important;
            &:hover {
                text-stroke: 2px #000!important;
                -webkit-text-stroke: 2px #000!important;
            }
        }
    }
    &::before {
        content: '';
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        background: linear-gradient(0deg, #C1A875 0%, #C1A875 100%), linear-gradient(339deg, rgba(255, 255, 255, 0.00) 52.79%, #FFF 95.95%), linear-gradient(77deg, #576265 11.6%, #9EA1A1 25.31%, #848B8A 48.06%, #576265 55.72%, #576265 77.23%, #757A7B 85.34%, #576265 91.31%);
        background-blend-mode: color, overlay, normal;
        transition: all .3s;
    }
    .title {
        position: absolute;
        z-index: 10;
        transition: all .3s;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background: #181719;
        padding: 0 25px;
        color: #FCF9F4;
        text-align: center;
        font-family: $righteous;
        font-weight: 400;
    }
    .lines {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 30px;
        @media(max-width: $md4+px) {
            gap: 40px;
        }
        .line {
            text-transform: uppercase;
            text-stroke: 0.6px rgba(197, 197, 197, 1);
            -webkit-text-stroke: 0.6px rgba(197, 197, 197, 1);
            color: transparent;
            transition: all .3s;
            font-family: $roboto;
            font-weight: 900;
        }
    }
}