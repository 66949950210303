@import "../../../styles/var";

.footer {
    position: relative;
    z-index: 10;
    border-top: 1px solid #fff;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    @media (max-width: $md6+px) {
        font-size: 14px;
        line-height: 21px;
        margin-top: 60px;
    }
    .content {
        padding-top: 26px;
        padding-bottom: 29px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        @media (max-width: $md5+px) {
            padding-top: 15px;
            padding-bottom: 22px;
        }
        .footer-word {
            position: absolute;
            right: 0;
            font-weight: 900;
            font-size: 250px;
            line-height: 0;
            z-index: 0;
            color: #d8d8d8;
        }
        .docs {
            position: relative;
            z-index: 2;
            display: flex;
            gap: 60px;
            margin-top: 20px;
            & > a {
                color: #fff;
                cursor: pointer;
                transition: all 0.3s;
                &:hover {
                    color: #D5B680;
                }
            }
            @media (max-width: $md4+px) {
                flex-direction: column;
                gap: 10px;
            }
        }
        .header {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            @media (max-width: $md5+px) {
                display: block;
            }
            .left {
                display: flex;
                align-items: flex-end;
                gap: 30px;
                @media (max-width: $md5+px) {
                    justify-content: space-between;
                }
                &Text {
                    cursor: pointer;
                    display: flex;
                    gap: 30px;
                    &-ru {
                        flex-direction: column;
                        gap: 5px;
                        @media (max-width: $md5+px) {
                            text-align: right;
                        }
                        @media (max-width: $md6+px) {
                            font-size: 12px !important;
                        }
                    }
                }
                .logo {
                    margin-right: 60px;
                    width: 79px;
                    @media(max-width: $md3+px) {
                        width: 70px;
                    }
                    @media (max-width: $md6+px) {
                        margin-right: 20px;
                        width: 60px;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
            .right {
                display: flex;
                gap: 30px;
                align-items: center;
                @media (max-width: $md5+px) {
                    margin-top: 33px;
                    justify-content: flex-start;
                }
                svg {
                    cursor: pointer;
                    path {
                        transition: all 0.3s;
                    }
                    &:hover {
                        path {
                            fill: #D5B680;
                        }
                    }
                }
            }
        }
        .info {
            position: relative;
            z-index: 2;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 6px;
            font-weight: 300;
            @media (max-width: $md5+px) {
                margin-top: 32px;
            }
            .address {
                @media (max-width: $md5+px) {
                    margin-top: 0;
                }
            }
        }
        .under {
            position: relative;
            z-index: 2;
            margin-top: 20px;
            display: flex;
            gap: 60px;
            @media (max-width: $md4+px) {
                flex-direction: column;
                gap: 0px;
            }
            @media (max-width: $md5+px) {
                margin-top: 32px;
            }
            .address {
                @media (max-width: $md5+px) {
                    margin-top: 0;
                }
            }
        }
    }
}
