@import '../../../styles/var';

.underBannerWrap {
    position: relative;

    @media(max-width: $md3+px) {
        margin-top: -80px;
    }

    @media(max-width: $md6+px) {
        margin-top: -120px;
    }

    .paint_01 {
        position: absolute;
        z-index: 1;
        left: 0;
        top: -455px;
        width: 184px;

        @media(max-width: $md3+px) {
            display: none;
        }
    }

    .paint_02 {
        position: absolute;
        z-index: 1;
        right: 0;
        width: 400px;
        top: 50px;

        @media(max-width: $md3+px) {
            display: none;
        }
    }
}

.underBanner {
    position: relative;

    .cards {
        position: relative;
        z-index: 10;
        display: flex;
        flex-direction: column;

        @media(max-width: 720px) {
            display: grid;
            // grid-template-rows: repeat(3, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 50px;
        }

        .card {
            position: relative;
            width: 510px;
            height: 224px;
            padding: 47px 51px;
            border-radius: 10px;
            border: 0.5px solid #918F90;
            background: linear-gradient(180deg, rgba(29, 27, 28, 0.50) 0%, rgba(25, 23, 24, 0.50) 81.19%, rgba(37, 33, 32, 0.50) 96.35%);
            box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
            backdrop-filter: blur(2px);

            @media(max-width: $md3+px) {
                width: 350px;
                padding: 30px;
            }

            @media(max-width: $md4+px) {
                padding: 0;
                background: none !important;
                box-shadow: none !important;
                backdrop-filter: none !important;
                border: none !important;
                width: 240px;
                height: 140px;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            @media(max-width: 720px) {
                width: 300px;
                height: auto;
                margin: 0 0 0 90px !important
            }

            @media(max-width: $md6+px) {
                width: 230px;
            }

            &::before,
            &::after {
                content: '';
                position: absolute;
                z-index: 4;
                height: calc(100% + 25px);
                top: -12px;
                width: 100%;
                background-repeat: no-repeat;
                background-size: contain;

                @media(max-width: $md4+px) {
                    display: none;
                }
            }

            &Title {
                position: relative;
                z-index: 11;
                color: #FCF9F4;
                font-family: $furore;
                font-size: 28px;
                font-weight: 400;
                line-height: normal;

                @media(max-width: $md4+px) {
                    font-size: 18px;
                }

                @media(max-width: $md6+px) {
                    font-size: 16px;
                }
            }

            &Text {
                position: relative;
                z-index: 11;
                margin-top: 17px;
                max-width: 330px;

                @media(max-width: $md4+px) {
                    margin-top: 15px;
                }
            }

            &:nth-child(1) {}

            &:nth-child(2) {
                margin-left: auto;
            }

            &:nth-child(3) {}

            &:nth-child(1),
            &:nth-child(3) {
                &::after {
                    right: -12px;
                    background-image: url('../../../assets/img/HomePage/TakeBenefits/border_right.png');
                    background-position: right center;
                }
            }

            &:nth-child(2) {
                &::before {
                    left: -12px;
                    background-image: url('../../../assets/img/HomePage/TakeBenefits/border_left.png');
                }
            }

            &:nth-child(2),
            &:nth-child(3) {
                margin-top: -20px;
            }
        }
    }

    .lineWrap {
        position: absolute;
        z-index: 5;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        .lineImg {
            position: absolute;
            z-index: 6;
            left: 50%;
            transform: translateX(calc(-50% - 4px));
            height: 120%;
            bottom: -10%;

            @media(max-width: 720px) {
                display: none;
            }
        }

        .line {
            position: absolute;
            z-index: 6;
            background: linear-gradient(91deg, rgba(179, 174, 135, 0.70) 47.62%, rgba(255, 249, 192, 0.70) 51.2%);
            box-shadow: 0px 0px 63.96px 0px #FFF, 0px 0px 127.92px 0px #FFF, 0px 0px 447.72px 0px #FFF, 0px 0px 895.44px 0px #FFF, 0px 0px 1535.04004px 0px #FFF, 0px 0px 2686.32007px 0px #FFF;
            filter: blur(2.5px);
            width: 4px;
            height: calc(100% - 224px/2);
            transform: translateX(-50%);
            left: 50%;
            bottom: 0;

            @media(max-width: $md4+px) {
                height: calc(100% - 140px/2);
            }

            @media(max-width: 720px) {
                display: none;
            }
        }

        .circle {
            position: absolute;
            z-index: 7;
            left: 50%;
            transform: translateX(-50%);
            width: 24px;
            height: 24px;
            background: #FFF;
            border-radius: 100%;
            filter: drop-shadow(0px 0px 2px #FFF) drop-shadow(0px 0px 4px #FFF) drop-shadow(0px 0px 15px #FFF) drop-shadow(0px 0px 30px #FFF) drop-shadow(0px 0px 51px #FFF) drop-shadow(0px 0px 90px #FFF);

            @media(max-width: 720px) {
                transform: none;
                left: calc(-40px - 12px);
                filter: drop-shadow(0px 0px 2px #FFF) drop-shadow(0px 0px 4px #FFF) drop-shadow(0px 0px 15px #FFF);
            }

            @media(max-width: 600px) {
                left: calc(-18px - 12px);
            }

            &::before {
                content: '';
                position: absolute;
                left: -64px;
                top: 0;
                height: 100%;
                width: 64px;
                background-image: url('../../../assets/img/HomePage/UnderBanner/arrow_left.svg');
                background-repeat: no-repeat;
                background-position: center right;
                background-size: contain;

                @media(max-width: 720px) {
                    left: unset;
                    right: -64px;
                    transform: rotate(180deg);
                }
            }

            // &_01 {
            //     bottom: calc(224px*2 + 224px/2 - 24px/2 - 40px);

            //     @media(max-width: $md4+px) {
            //         bottom: calc(140px*2 + 140px/2 - 24px/2 - 40px);
            //     }

            //     @media(max-width: 720px) {
            //         top: calc(100% / 3 / 3 - 8px);
            //     }
            // }

            &_02 {
                bottom: calc(224px + 224px/2 - 24px/2 - 20px);

                @media(max-width: $md4+px) {
                    bottom: calc(140px + 140px/2 - 24px/2 - 20px);
                }

                @media(max-width: 720px) {
                    // bottom: calc(100% / 2 - 12px)
                    top: 30px;
                }

                // &::before {
                //     transform: rotate(180deg);
                //     right: -64px;
                //     left: unset;
                // }
            }

            &_03 {
                bottom: calc(224px/2 - 24px/2);

                @media(max-width: $md4+px) {
                    bottom: calc(140px/2 - 24px/2);
                }

                @media(max-width: 720px) {
                    // bottom: calc(100% / 3 / 3 - 10px);
                    bottom: 30px;
                }

                &::before {
                    transform: rotate(180deg);
                    right: -64px;
                    left: unset;
                }
            }
        }
    }

    .portal {
        position: absolute;
        bottom: -210px;
        left: 50%;
        transform: translateX(-50%);
        width: 982px;

        @media(max-width: $md4+px) {
            width: 768px;
            bottom: -170px;
        }

        @media(max-width: 720px) {
            left: 0;
            transform: translateX(calc(-50% - 40px));
            bottom: -230px;
        }

        @media(max-width: 600px) {
            transform: translateX(calc(-50% - 18px));
        }
    }
}