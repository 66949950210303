@import '../../../styles/var';

.joinToWrap {
    position: relative;
}

.joinTo {
    position: relative;
    z-index: 10;
    .content {
        position: relative;
        z-index: 10;
        .title {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            @media(max-width: $md3+px) {
                position: relative;
                top: unset;
                left: unset;
                text-align: center;
                margin-bottom: 80px;
            }
            @media(max-width: $md6+px) {
                margin-bottom: 60px;
            }
            span {
                color: #FCF9F4;
            }
            &Text_01 {
                text-align: right;
                padding-right: 112px;
                @media(max-width: $md3+px) {
                    text-align: center;
                    padding-right: 0;
                    padding-left: 70px;
                }
                @media(max-width: $md4+px) {
                    padding-left: 50px;
                }
                @media(max-width: $md6+px) {
                    padding-left: 40px;
                }
            }
            &Text_02 {
                margin: -50px 0 -5px 0;
                @media(max-width: $md4+px) {
                    margin: -40px 0 0 0;
                }
                @media(max-width: $md6+px) {
                    margin: -30px 0 -3px 0;
                }
            }
        }
    }
    .cards {
        position: relative;
        display: flex;
        flex-direction: column;
        @media(max-width: $md3+px) {
            display: flex;
            flex-direction: column;
            align-items: unset;
        }
        @media(max-width: 800px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 80px;
        }
        @media(max-width: 700px) {
            display: flex;
            gap: 60px;
        }
        .card {
            position: relative;
            width: 448px;
            @media(max-width: $md4+px) {
                width: 400px;
            }
            @media(max-width: 800px) {
                margin: 0 auto!important;
                width: 100%;
            }
            @media(max-width: 700px) {
                width: 300px;
            }
            @media(max-width: $md6+px) {
                width: 260px;
            }
            &_01 {
                margin-left: auto;
                margin-right: 40px;
                @media(max-width: $md4+px) {
                    margin-right: 0;
                }
                .cardPerson {
                    z-index: 7;
                }
            }
            &_02 {
                margin-left: 40px;
                @media(max-width: $md4+px) {
                    margin-left: 0;
                }
            }
            &_03 {
                margin-left: auto;
                margin-right: 40px;
                @media(max-width: $md4+px) {
                    margin-right: 0;
                }
            }
            &_04 {
                margin-left: 40px;
                @media(max-width: $md4+px) {
                    margin-left: 0;
                }
            }
            &_02, &_03, &_04 {
                margin-top: -40px;
            }
            &Person {
                position: relative;
                z-index: 3;
                display: flex;
                margin: 0 auto;
                height: 300px;
                @media(max-width: $md3+px) {
                    height: 240px;
                }
                @media(max-width: $md4+px) {
                    height: 220px;
                }
                @media(max-width: 800px) {
                    height: 190px;
                }
                @media(max-width: $md6+px) {
                    height: 150px;
                }
            }
            &Content {
                position: relative;
                z-index: 5;
                width: 100%;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                border-radius: 10px;
                border: 0.5px solid #918F90;
                background: linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
                text-shadow: 0px 0px 2px rgba(241, 237, 238, 0.40);

                @media(max-width: $md6+px) {
                    height: 48px;
                }
                // &:hover {
                //     background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                //     box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.5px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
                // }
                &Text {
                    position: relative;
                    z-index: 5;
                    color: #F1EDEE;
                    font-family: $furore;
                }
            }
        }
    }
}