@import '../../../styles/var';

.takeBenefitsWrap {
    max-width: 1114px;
    margin: 0 auto;
}

.takeBenefits {
    position: relative;
    &::before, &::after {
        content: '';
        position: absolute;
        z-index: 4;
        height: calc(100% + 25px);
        top: -12px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        @media(max-width: $md6+px) {
            display: none;
        }
    }
    &::before {
        left: -12px;
        background-image: url('../../../assets/img/HomePage/TakeBenefits/border_left.png');
        background-position: left center;
    }
    &::after {
        right: -12px;
        background-image: url('../../../assets/img/HomePage/TakeBenefits/border_right.png');
        background-position: right center;
    }
    .content {
        position: relative;
        z-index: 10;
        padding: 42px 67px;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 30px;
        
        border-radius: 10px;
        border: 0.5px solid #918F90;
        background: linear-gradient(180deg, rgba(29, 27, 28, 0.50) 0%, rgba(25, 23, 24, 0.50) 81.19%, rgba(37, 33, 32, 0.50) 96.35%);
        box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
        backdrop-filter: blur(2px);

        @media(max-width: $md4+px) {
            gap: 25px;
            padding: 35px 50px;
        }
        @media(max-width: $md6+px) {
            gap: 30px;
            padding: 28px;
        }
        // &:hover {
        //     background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
        //     box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.5px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
        // }
        .text_01 {
            color: #FFF;
            font-family: $mont;
            font-size: 22px;
            font-weight: 300;
            line-height: 160%;
            @media(max-width: $md4+px) {
                font-size: 16px;
            }
            @media(max-width: $md6+px) {
                font-size: 14px;
            }
        }
        .text_02 {
            color: #FCF9F4;
            font-family: $furore;
            font-size: 28px;
            font-weight: 400;
            line-height: normal;
            @media(max-width: $md4+px) {
                font-size: 23px;
            }
            @media(max-width: $md6+px) {
                font-size: 20px;
            }
        }
        .button {
            cursor: pointer;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            padding: 24px 34px;
            border-radius: 74px;
            border: 0.5px solid #918F90;
            background: linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
            box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
            color: #F1EDEE;
            text-shadow: 0px 0px 2px rgba(241, 237, 238, 0.40);
            font-family: $furore;
            font-size: 18px;
            font-weight: 400;
            line-height: normal;
            @media(max-width: $md4+px) {
                font-size: 16px;
                padding: 21px 34px;
            }
            @media(max-width: 600px) {
                // display: flex;
                // width: 100%;
            }
            @media(max-width: $md6+px) {
                padding: 17px 24px;
                font-size: 14px;
            }
            &:hover {
                background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.5px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
            }
        }
    }
}