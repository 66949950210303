.loader {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 1;
  transition: all 1.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
}
