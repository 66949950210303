@import "./_var.scss";

body,
html {
    background-color: #000;
    color: #fff;
    &.lock {
        overflow: hidden;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background-color: #000;
    }
    ::-webkit-scrollbar-thumb {
        background: #FFF9C0;
    }
}

.font-120 {
    color: #F9E108;
    font-family: $righteous;
    font-size: 120px;
    font-weight: 400;
    line-height: normal;
    @media(max-width: $md4+px) {
        font-size: 100px;
    }
    @media(max-width: $md6+px) {
        font-size: 80px;
    }
}

.font-32 {
    color: #FFF;
    font-family: $caveat;
    font-size: 32px;
    font-weight: 400;
    line-height: normal;
    @media(max-width: $md4+px) {
        font-size: 30px;
    }
    @media(max-width: $md6+px) {
        font-size: 23px;
    }
}

.font-23 {
    color: #fff;
    text-align: center;
    font-family: $mont;
    font-size: 23px;
    font-weight: 400;
    line-height: 140%;
    @media(max-width: $md4+px) {
        font-size: 20px;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font-18 {
    color: #FFF;
    font-family: $mont;
    font-size: 18px;
    font-weight: 300;
    line-height: 150%;
    @media(max-width: $md4+px) {
        font-size: 16px;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.mt {
    margin-top: 200px;
    @media (max-width: $md4+px) {
        margin-top: 145px;
    }
    @media (max-width: $md6+px) {
        margin-top: 140px;
    }
}

.pt {
    padding-top: 200px;
    @media (max-width: $md4+px) {
        padding-top: 145px;
    }
    @media (max-width: $md6+px) {
        padding-top: 140px;
    }
}

.pb {
    padding-bottom: 200px;
    @media (max-width: $md4+px) {
        padding-bottom: 145px;
    }
    @media (max-width: $md6+px) {
        padding-bottom: 140px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Dropdown-control {
    font-family: $mont;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding-right: 30px;
    cursor: pointer;
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $mont;
    font-style: normal;
    font-size: 14px;
}
.Dropdown-arrow {
    border-color: #fff transparent transparent;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent #fff;
}

.site-content {
    position: relative;
    z-index: 3;
}


.docsPage {
    margin-top: 180px;
    font-family: $mont;
    @media(max-width: $md4+px) {
        margin-top: 140px;
    }
    @media(max-width: $md6+px) {
        margin-top: 100px;
    }
    .docsViewTitle {
        font-weight: 700;
        font-size: 55px;
        line-height: 140%;
        color: #fff;
        @media(max-width: $md4+px) {
            font-size: 48px;
        }
        @media(max-width: $md6+px) {
            font-size: 24px;
        }
    }
    .docsViewBody {
        font-weight: 300;
        font-size: 16px;
        line-height: 150%;
        color: #fff;
        margin-top: 47px;
        @media(max-width: $md6+px) {
            font-size: 14px;
            margin-top: 30px;
        }
        .docsViewBodyListDefis {
            margin-top: 14px;
            padding-left: 20px;
            @media(max-width: $md6+px) {
                margin-top: 10px;
                padding-left: 10px;
            }
            .docsViewBodyPar {
                margin-top: 5px;
            }
        }
        .docsViewBodyBlock {
            margin-top: 30px;
            @media(max-width: $md6+px) {
                margin-top: 25px;
            }
        }
        .docsViewBodyTitle {
            font-weight: 500;
            color: #fff;
        }
        .docsViewBodyPar {
            margin-top: 10px;
            @media(max-width: $md6+px) {
                margin-top: 8px;
            }
            a {
                color: #fff;
                text-decoration: underline;
            }
        }
    }
}
.italic {
    font-style: italic;
}