@import '../../../styles/var';

.blocksWrap {
    position: relative;
    .bg {
        position: absolute;
        z-index: 2;
        width: 1524px;
        top: -253px;
        left: 50%;
        transform: translateX(-50%);
        @media(max-width: $md4+px) {
            width: 1300px;
            top: -200px;
        }
        @media(max-width: $md5+px) {
            top: -170px;
        }
        @media(max-width: $md6+px) {
            width: 1000px;
            top: -110px;
        }
    }
}

.blocks {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    gap: 90px;
    @media(max-width: $md3+px) {
        gap: 40px;
    }
    @media(max-width: $md5+px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 42px;
    }
    .block {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 30px;
        text-align: center;
        color: #fff;
        font-family: $furore;

        border-radius: 10px;
        border: 0.5px solid #918F90;
        background: linear-gradient(180deg, rgba(29, 27, 28, 0.50) 0%, rgba(25, 23, 24, 0.50) 81.19%, rgba(37, 33, 32, 0.50) 96.35%);
        box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
        backdrop-filter: blur(2px);
        // &:hover {
        //     background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
        //     box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.5px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
        // }
    }
}