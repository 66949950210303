@import '../../../styles/var';

.formWrap {
    position: fixed;
    z-index: 99999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transition: all .3s;

    pointer-events: none;
    opacity: 0;
    backdrop-filter: blur(25px);
    background: rgba(196, 196, 196, 0.05);
    @media(max-width: 700px) {
        background: rgba(0, 0, 0, 0.7);
    }
    &Active {
        opacity: 1;
        pointer-events: visible;
    }
    .closeArea {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        @media(max-width: 700px) {
            display: none;
        }
    }
}

.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    @media(max-width: 700px) {
        display: block!important;
        padding-top: 120px!important;
        padding-bottom: 120px!important;
        overflow: scroll;
    }
    @media(max-height: 500px) {
        overflow: scroll;
        align-items: flex-start;
    }
}

.form {
    position: relative;
    z-index: 5;
    width: 626px;
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.7);
    padding: 50px;
    @media(max-width: 700px) {
        background: transparent;
        padding: 0;
        width: 100%;
        border-radius: 0;
    }
    &.formSuccess {
        .formContent {
            filter: blur(6px);
            pointer-events: none;
        }
        .successPopup {
            pointer-events: visible;
            opacity: 1;
            &Img {
                &::before {
                    transform: scale(1)!important;
                }
            }
        }
    }
    .close {
        position: absolute;
        z-index: 20;
        width: 20px;
        height: 20px;
        top: 0;
        right: -40px;
        cursor: pointer;
        @media(max-width: 700px) {
            top: -50px!important;
            right: 0!important;
        }
        @media(max-height: 500px) {
            top: 10px;
            right: 10px;
        }
        &::before {
            content: '';
            position: absolute;
            width: 28px;
            height: 2px;
            background: #fff;
            transform: rotate(45deg);
            top: 49%;
            left: -5px;
        }
        &::after {
            content: '';
            position: absolute;
            width: 28px;
            height: 2px;
            background: #fff;
            transform: rotate(-45deg);
            top: 49%;
            left: -5px;
        }
    }
    .successPopup {
        position: absolute;
        border-radius: 20px;
        width: 100%;
        height: 100%;
        background: rgba($color: #000, $alpha: 0.3);
        z-index: 99999999;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 0;
        top: 0;
        pointer-events: none;
        opacity: 0;
        gap: 30px;
        @media(max-width: 700px) {
            background: transparent;
            flex-direction: column;
            text-align: center;
        }
        @media(max-width: $md6+px) {
            gap: 20px;
        }
        &Text {
            font-size: 16px!important;
            line-height: 130%;
            @media(max-width: 700px) {
                max-width: 350px;
            }
        }
        &Img {
            position: relative;
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            @media(max-width: $md6+px) {
                width: 40px;
                height: 40px;
            }
            svg {
                transition: all .3s;
                @media(max-width: $md6+px) {
                    transform: scale(0.7);
                }
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 100px;
                border: 4px solid #fff;
                transform: scale(1.5);
                transition: all .3s;
                @media(max-width: $md6+px) {
                    border-width: 3px;
                }
            }
        }
    }
    &Content {
        position: relative;
        z-index: 10;
        transition: all .3s;
        .inputs {
            display: flex;
            flex-direction: column;
            gap: 15px;
            .inputWrap {
                width: 100%;
                border-radius: 30px;
                border: 1px solid #fff;
                padding: 1px;
                height: 50px;
                @media(max-width: $md6+px) {
                    height: 46px;
                }
                &.borderRed {
                    border-color: red!important;
                }
                .input {
                    width: 100%;
                    height: 100%;
                    padding: 0 30px;
                    background: transparent;
                    color: #FFF;
                    font-family: $mont;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                    }
                    &::placeholder {
                        color: rgba($color: #fff, $alpha: 0.5);
                    }
                }
            }
            .check {
                display: grid;
                grid-template-columns: 16px 1fr;
                gap: 10px;
                &Red {
                    .checkText {
                        color: red;
                    }
                }
                &Box {
                    position: relative;
                    cursor: pointer;
                    width: 16px;
                    height: 16px;
                    border: 1px solid #fff;
                    &::before {
                        content: '';
                        opacity: 0;
                        position: absolute;
                        background: #fff;
                        width: 10px;
                        height: 10px;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        transition: all .1s;
                    }
                    &Active {
                        &::before {
                            opacity: 1;
                        }
                    }
                }
                &Text {
                    font-size: 14px;
                    line-height: 140%;
                    a {
                        cursor: pointer;
                        color: #fff;
                        text-decoration: underline;
                    }
                }
            }
            .row {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                @media(max-width: 700px) {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                }
                .col {
    
                }
            }
        }
        .btnWrap {
            display: flex;
            justify-content: flex-end;
            margin-top: 25px;
            .button {
                cursor: pointer;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                padding: 17px 23px;
                border-radius: 74px;
                border: 0.5px solid #918F90;
                background: linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
                color: #F1EDEE;
                text-shadow: 0px 0px 2px rgba(241, 237, 238, 0.40);
                font-family: $furore;
                font-size: 18px;
                font-weight: 400;
                line-height: normal;
                @media(max-width: $md4+px) {
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
                &:hover {
                    background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
                    box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.5px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
                }
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }
        }
    }
}



:global(.Dropdown-root) {
    display: flex;
    align-items: center!important;
}
:global(.Dropdown-control) {
    background-color: transparent!important;
    border: none!important;
    cursor: pointer!important;
    display: flex;
    align-items: center;

    width: 100%;
    font-family: $mont;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    color: #fff!important;
    background: transparent;
    height: 46px;
    padding: 0 30px;
    border-radius: 20px;
    text-transform: none!important;
    @media(max-width: $md6+px) {
        font-size: 14px;
        height: 40px;
    }
    &::placeholder {
        color: rgba($color: #fff, $alpha: 0.5);
    }
    &:hover {
        box-shadow: none;
    }
}
:global(.Dropdown-placeholder) {
    color: rgba(255, 255, 255, 0.50);
}
:global(.Dropdown-placeholder.is-selected) {
    color: #fff;
}
:global(.Dropdown-menu) {
    font-family: $mont;
    font-weight: 400;
    font-size: 16px;
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}
:global(.Dropdown-arrow) {
    top: calc(50% - 3px)!important;
}
:global(.Dropdown-option) {
}
:global(.is-open .Dropdown-arrow) {
}
